import axios, { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { Dispatch } from "redux";
import toastr from "src/components/Common/toastr";
import { AppAnalytics } from "src/helpers/analytics_helper";
import { DashboardBackendAxiosError } from "src/types/customTypes";
import { Session } from "src/types/sessions";
import { v4 as uuid } from "uuid";
import api from "../../api";
import { API_CALL_STATE } from "../../constants";
import { currentUser, getAPIURL, uniqueObjects } from "../../utils";

export const RESET_ROOM_SESSION_DETAILS = "RESET_ROOM_SESSION_DETAILS";
export const FETCH_ROOM_TOKEN_DETAILS_INIT = "FETCH_ROOM_TOKEN_DETAILS_INIT";
export const FETCH_ROOM_TOKEN_DETAILS_DONE = "FETCH_ROOM_TOKEN_DETAILS_DONE";
export const FETCH_ROOM_TOKEN_DETAILS_FAIL = "FETCH_ROOM_TOKEN_DETAILS_FAIL";
export const SET_ROOM_FILTER = "SET_ROOM_FILTER";
export const FETCH_ROOM_SESSION_DETAILS_INIT =
  "FETCH_ROOM_SESSION_DETAILS_INIT";
export const FETCH_ROOM_SESSION_DETAILS_DONE =
  "FETCH_ROOM_SESSION_DETAILS_DONE";
export const FETCH_ROOM_SESSION_DETAILS_FAIL =
  "FETCH_ROOM_SESSION_DETAILS_FAIL";

export const FETCH_ROOM_SHORT_CODE_FOR_ROLE_INIT =
  "FETCH_ROOM_SHORT_CODE_FOR_ROLE_INIT";
export const FETCH_ROOM_SHORT_CODE_FOR_ROLE_DONE =
  "FETCH_ROOM_SHORT_CODE_FOR_ROLE_DONE";
export const FETCH_ROOM_SHORT_CODE_FOR_ROLE_FAIL =
  "FETCH_ROOM_SHORT_CODE_FOR_ROLE_FAIL";

export const FETCH_ROOM_DETAILS_INIT = "FETCH_ROOM_DETAILS_INIT";
export const FETCH_ROOM_DETAILS_DONE = "FETCH_ROOM_DETAILS_DONE";
export const FETCH_ROOM_DETAILS_FAIL = "FETCH_ROOM_DETAILS_FAIL";

export const UPDATE_ROOM_DETAILS_INIT = "UPDATE_ROOM_DETAILS_INIT";
export const UPDATE_ROOM_DETAILS_DONE = "UPDATE_ROOM_DETAILS_DONE";
export const UPDATE_ROOM_DETAILS_FAIL = "UPDATE_ROOM_DETAILS_FAIL";

export const FETCH_RECENT_ROOMS_INIT = "FETCH_RECENT_ROOMS_INIT";
export const FETCH_RECENT_ROOMS_DONE = "FETCH_RECENT_ROOMS_DONE";
export const FETCH_RECENT_ROOMS_FAIL = "FETCH_RECENT_ROOMS_FAIL";

export const FETCH_ROOM_CODES_FOR_ALLROLES_INIT =
  "FETCH_ROOM_CODES_FOR_ALLROLES_INIT";
export const FETCH_ROOM_CODES_FOR_ALLROLES_DONE =
  "FETCH_ROOM_CODES_FOR_ALLROLES_DONE";
export const FETCH_ROOM_CODES_FOR_ALLROLES_FAIL =
  "FETCH_ROOM_CODES_FOR_ALLROLES_FAIL";

export const FETCH_ROOM_RTMP_STREAM_KEYS_INIT =
  "FETCH_ROOM_RTMP_STREAM_KEYS_INIT";
export const FETCH_ROOM_RTMP_STREAM_KEYS_DONE =
  "FETCH_ROOM_RTMP_STREAM_KEYS_DONE";
export const FETCH_ROOM_RTMP_STREAM_KEYS_FAIL =
  "FETCH_ROOM_RTMP_STREAM_KEYS_FAIL";

export const RESET_ROOM_DETAILS = "RESET_ROOM_DETAILS";

export const CREATE_ROOM_RTMP_STREAM_KEYS_INIT =
  "CREATE_ROOM_RTMP_STREAM_KEYS_INIT";
export const CREATE_ROOM_RTMP_STREAM_KEYS_DONE =
  "CREATE_ROOM_RTMP_STREAM_KEYS_DONE";
export const CREATE_ROOM_RTMP_STREAM_KEYS_FAIL =
  "CREATE_ROOM_RTMP_STREAM_KEYS_FAIL";

export const SET_ROOM_SESSION_DERIVED_STATE = "SET_ROOM_SESSION_DERIVED_STATE";

export const DISABLE_ROOM_RTMP_STREAM_KEYS_INIT =
  "DISABLE_ROOM_RTMP_STREAM_KEYS_INIT";
export const DISABLE_ROOM_RTMP_STREAM_KEYS_DONE =
  "DISABLE_ROOM_RTMP_STREAM_KEYS_DONE";
export const DISABLE_ROOM_RTMP_STREAM_KEYS_FAIL =
  "DISABLE_ROOM_RTMP_STREAM_KEYS_FAIL";

export function fetchRoomToken(identifier: string, role: string) {
  return async (dispatch: Dispatch, getState: any) => {
    const { fetchRoomTokenStatus, roomRoleTokens } = getState().rooms;
    if (
      fetchRoomTokenStatus === API_CALL_STATE.IN_PROGRESS ||
      !isEmpty(roomRoleTokens?.[role]) ||
      !identifier
    ) {
      return;
    }
    dispatch({ type: FETCH_ROOM_TOKEN_DETAILS_INIT });
    try {
      const resp = await api
        .service("auth")
        .post("token", { code: identifier, user_id: uuid() });

      dispatch({
        type: FETCH_ROOM_TOKEN_DETAILS_DONE,
        payload: resp.data,
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: FETCH_ROOM_TOKEN_DETAILS_FAIL });
      toastr.error(message);
    }
  };
}

export function fetchRoomShortCodeForRole(
  roomId: string,
  role: string,
  createNew: boolean
) {
  return async (dispatch: Dispatch, getState: any) => {
    const { fetchRoomShortCodeStatus, roomShortCodeForRoles } =
      getState().rooms;
    if (
      fetchRoomShortCodeStatus === API_CALL_STATE.IN_PROGRESS ||
      !isEmpty(roomShortCodeForRoles?.[role])
    ) {
      return;
    }
    dispatch({ type: FETCH_ROOM_SHORT_CODE_FOR_ROLE_INIT });
    try {
      const resp = await api.service("dashboard").get(getAPIURL("room-codes"), {
        room_id: roomId,
        role: role,
        create_new: createNew,
      }); // Not sure about the params required
      if (resp.data.success) {
        dispatch({
          type: FETCH_ROOM_SHORT_CODE_FOR_ROLE_DONE,
          payload: resp.data.data, // Or the shape of the resp
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: FETCH_ROOM_SHORT_CODE_FOR_ROLE_FAIL });
      toastr.error(message);
    }
  };
}

export function fetchRoomDetails(id?: string) {
  return async (dispatch: Dispatch, getState: any) => {
    const { fetchRoomDetailsStatus } = getState().rooms;
    if (fetchRoomDetailsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ROOM_DETAILS_INIT });
    const user = currentUser();
    try {
      await api
        .service("dashboard")
        .get("rooms", { email: user.email, id })
        .then(res => {
          dispatch({
            type: FETCH_ROOM_DETAILS_DONE,
            payload: res.data,
          });
        })
        .catch(err => {
          const e = err as AxiosError<DashboardBackendAxiosError> | Error;
          let message = "";
          if (axios.isAxiosError(e)) {
            message = e.response?.data?.msg;
          } else {
            message = e.message;
          }
          console.error(message);
          dispatch({ type: FETCH_ROOM_DETAILS_FAIL });
          toastr.error(message);
        });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: FETCH_ROOM_DETAILS_FAIL });
      toastr.error(message);
    }
  };
}

export function fetchRoomTokenAndShortCode(roomId: string, role: string) {
  return async (dispatch: Dispatch, getState: any) => {
    // @ts-ignore
    await dispatch(fetchRoomShortCodeForRole(roomId, role, false));
    const roomShortCodeForRoles = getState().rooms.roomShortCodeForRoles;
    // @ts-ignore
    await dispatch(fetchRoomToken(roomShortCodeForRoles[role].value, role));
  };
}

export function fetchShortCodeForAll(roomId: string) {
  return async (dispatch: Dispatch, getState: any) => {
    const { fetchAllRoomCodesStatus } = getState().rooms;
    if (fetchAllRoomCodesStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ROOM_CODES_FOR_ALLROLES_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(getAPIURL("room-codes/all"), {
          room_id: roomId,
        });
      if (resp.data.success) {
        dispatch({
          type: FETCH_ROOM_CODES_FOR_ALLROLES_DONE,
          payload: resp.data.data,
        });
      } else {
        throw new Error(resp.data.msg);
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({
        type: FETCH_ROOM_CODES_FOR_ALLROLES_FAIL,
        payload: {
          ...(e as AxiosError<DashboardBackendAxiosError>)?.response?.data,
          msg: message,
        },
      });
      toastr.error(message);
    }
  };
}

export function fetchRecentRooms() {
  return async (dispatch: Dispatch, getState: any) => {
    const { fetchRecentRoomsStatus } = getState().rooms;
    if (fetchRecentRoomsStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_RECENT_ROOMS_INIT });
    try {
      const resp = await api.service("dashboard").get(getAPIURL("rooms"), {
        limit: 5,
        get_room_details: true,
        get_session_status: true,
      });

      dispatch({
        type: FETCH_RECENT_ROOMS_DONE,
        payload: resp.data.data,
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: FETCH_RECENT_ROOMS_FAIL });
      toastr.error(message);
    }
  };
}

export function updateRoomDetails({
  largeRoom,
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
  largeRoom?: boolean;
}) {
  return async (dispatch: Dispatch, getState: any) => {
    const { updateRoomStatus } = getState().rooms;
    if (updateRoomStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_ROOM_DETAILS_INIT });
    try {
      const resp = await api.service("dashboard").post("update-room", {
        id: id,
        active: enabled,
        large_room: largeRoom,
      });
      dispatch({
        type: UPDATE_ROOM_DETAILS_DONE,
        payload: resp.data.data,
      });
      if (resp?.data?.data?.active) {
        //@ts-ignore
        dispatch(fetchRoomRTMPStreamKeys(id));
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: UPDATE_ROOM_DETAILS_FAIL });
      toastr.error(message);
    }
  };
}

export function fetchRoomRTMPStreamKeys(roomId: string) {
  return async (dispatch: Dispatch, getState: any) => {
    const { fetchRoomRTMPStreamKeysStatus } = getState().rooms;
    if (fetchRoomRTMPStreamKeysStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ROOM_RTMP_STREAM_KEYS_INIT });
    try {
      await api
        .service("dashboard")
        .get(getAPIURL(`rooms/${roomId}/stream-key`))
        .then(resp => {
          dispatch({
            type: FETCH_ROOM_RTMP_STREAM_KEYS_DONE,
            payload: { ...resp.data.data, roomId: roomId },
          });
        })
        .catch(e => {
          throw new Error(e);
        });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: FETCH_ROOM_RTMP_STREAM_KEYS_FAIL });
    }
  };
}

export function createRoomRTMPStreamKeys(roomId: string) {
  return async (dispatch: Dispatch, getState: any) => {
    const { createRoomRTMPStreamKeysStatus } = getState().rooms;
    if (createRoomRTMPStreamKeysStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: CREATE_ROOM_RTMP_STREAM_KEYS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(`rooms/${roomId}/stream-key/create`);
      dispatch({
        type: CREATE_ROOM_RTMP_STREAM_KEYS_DONE,
        payload: { ...resp.data.data, roomId: roomId },
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: CREATE_ROOM_RTMP_STREAM_KEYS_FAIL });
    }
  };
}

export function disableRoomRTMPStreamKeys(roomId: string) {
  return async (dispatch: Dispatch, getState: any) => {
    const { disableRoomRTMPStreamKeysStatus } = getState().rooms;
    if (disableRoomRTMPStreamKeysStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: DISABLE_ROOM_RTMP_STREAM_KEYS_INIT });
    try {
      const resp = await api
        .service("dashboard")
        .post(`rooms/${roomId}/stream-key/disable`);
      dispatch({
        type: DISABLE_ROOM_RTMP_STREAM_KEYS_DONE,
        payload: { ...resp.data.data, roomId: roomId },
      });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: DISABLE_ROOM_RTMP_STREAM_KEYS_FAIL });
    }
  };
}

export function fetchRoomSessionDetails({ roomId }: { roomId: string }) {
  return async (dispatch: Dispatch, getState: any) => {
    const fetchRoomSessionsStatus = getState().rooms.fetchRoomSessionsStatus;
    const derivedRoomSessionsState = getState().rooms.derivedRoomSessionsState;
    const roomSessionDetails = getState().rooms.roomSessionDetails;
    if (
      fetchRoomSessionsStatus === API_CALL_STATE.IN_PROGRESS ||
      derivedRoomSessionsState.seriesEnded
    ) {
      return;
    }
    dispatch({ type: FETCH_ROOM_SESSION_DETAILS_INIT });
    let params = {
      room_id: roomId,
      limit: "20",
      start: "",
      peercount_only: "true",
    } as Partial<{
      session_id: string;
      room_id: string;
      limit: string;
      start: string;
      peercount_only: string;
    }>;

    if (derivedRoomSessionsState?.id) {
      params = {
        ...params,
        session_id: derivedRoomSessionsState.id,
      };
    }
    if (derivedRoomSessionsState?.last) {
      params = {
        ...params,
        start: derivedRoomSessionsState.last,
      };
    }

    await api
      .service("dashboard")
      .get(getAPIURL("room-sessions"), { ...params })
      .then(response => {
        if (response.data.success) {
          // FIX: Ideally should store response.data.data in sessions as is.

          const sessions =
            response?.data?.data?.filter(
              (session: Session) => session.room_id === roomId
            ) || [];
          AppAnalytics.track("fetch.room_session.succes", {
            page: "/room-details",
            room_id: roomId,
          });
          const moreSessions = roomSessionDetails;
          const moreSessionsLength = moreSessions.length;
          const totalSessions = uniqueObjects(
            [...moreSessions, ...sessions],
            "session_id"
          );

          dispatch({
            type: FETCH_ROOM_SESSION_DETAILS_DONE,
            payload: totalSessions,
          });
          let ended = false;
          if (moreSessionsLength === totalSessions.length) {
            ended = true;
          }
          dispatch({
            type: SET_ROOM_SESSION_DERIVED_STATE,
            payload: {
              last: response?.data?.last,
              seriesEnded: ended,
            },
          });
        } else {
          console.error("Room-sessions error: ", response.data.msg);
          dispatch({ type: FETCH_ROOM_SESSION_DETAILS_FAIL });

          toastr.error(`Error fetching room sessions : ${response.data.msg}`);
        }
      })
      .catch(err => {
        console.error("Room-sessions error: ", err);
        dispatch({ type: FETCH_ROOM_SESSION_DETAILS_FAIL });
        AppAnalytics.track("fetch.room_session.failure", {
          page: "/room-details",
          room_id: roomId,
        });
        const e = err as AxiosError<DashboardBackendAxiosError> | Error;
        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        toastr.error(`Error fetching room sessions : ${message}`);
      });
  };
}

export const setDerivedRoomSessionsStateInStore = (
  derivedState: Partial<{
    id: string;
    last: string;
    seriesEnded: boolean;
  }>
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SET_ROOM_SESSION_DERIVED_STATE, payload: derivedState });
  };
};

export const resetRoomSessionsDetails = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET_ROOM_SESSION_DETAILS });
  };
};

export const resetRoomDetails = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RESET_ROOM_DETAILS });
  };
};
