import { DEFAULT_FRAMERATE } from "src/constants";
import {
  policyTemplateType,
  roleTemplatePolicy,
  templatePluginTypes,
  whiteboardPermissionsType,
} from "src/types/policyTypes";
import { defaultHLSVideoLayers as defaultLayers } from "src/utils";

export const roleTemplateForPolicy: roleTemplatePolicy = {
  name: "",
  publishParams: {
    allowed: ["audio", "video", "screen"],
    audio: {
      bitRate: 32,
      codec: "opus",
    },
    video: {
      width: 960,
      height: 720,
      frameRate: DEFAULT_FRAMERATE,
      bitRate: 700,
      codec: "vp8",
    },
    screen: {
      codec: "vp8",
      frameRate: 10,
      height: 1080,
      width: 1920,
    },
    simulcast: {
      video: {
        layers: [
          {
            rid: "f",
            scaleResolutionDownBy: 1,
            maxBitrate: 700,
            maxFramerate: DEFAULT_FRAMERATE,
          },
          {
            rid: "h",
            scaleResolutionDownBy: 2,
            maxBitrate: 250,
            maxFramerate: DEFAULT_FRAMERATE,
          },
          {
            rid: "q",
            scaleResolutionDownBy: 4,
            maxBitrate: 100,
            maxFramerate: DEFAULT_FRAMERATE,
          },
        ],
      },
      screen: {},
    },
  },
  subscribeParams: {
    subscribeToRoles: [],
    maxSubsBitRate: 3200,
    subscribeDegradation: {
      packetLossThreshold: 25,
      degradeGracePeriodSeconds: 1,
      recoverGracePeriodSeconds: 4,
    },
  },
  permissions: {
    rtmpStreaming: false,
    hlsStreaming: false,
    browserRecording: false,
    sendRoomState: true,
    removeOthers: false,
    mute: false,
    unmute: false,
    changeRole: false,
    endRoom: false,
  },
  priority: 1,
  maxPeerCount: 0,
};

export const hlsDestinations = {
  default: {
    name: "default",
    role: "",
    maxDuration: 14400, // 30*60, 4*60*60, 8*60*60
    layers: [...defaultLayers],
    playlistType: "live", // event or live
    numPlaylistSegments: 10, // 3, 5,12
    videoFrameRate: 25, // 10, 20,30
    displayAspectRatio: "", // Not supported
    enableMetadataInsertion: false,
    enableStaticUrl: false,
    preset: "", //tune_efficiency, tune_quality, default, fullHD
    recording: {
      hlsVod: false,
      singleFilePerLayer: false,
      thumbnails: {
        enabled: false,
        width: defaultLayers[0].width, //240, width, width
        height: defaultLayers[0].height, //240, height, height
        offsets: [
          60, // Only one offset supported
        ],
      },
      presignDuration: 259200, //60*60, 3*24*60*60, 7*24*60*60
    },
  },
};
export const pluginsTemplate: templatePluginTypes = {
  whiteboard: {
    permissions: {
      admin: [],
      writer: [],
      reader: [],
    } as whiteboardPermissionsType,
  },
};
export const policyTemplate: policyTemplateType = {
  id: "",
  name: "",
  customer: "",
  roles: {},
  settings: {
    region: "",
    roomState: {
      messageInterval: 10,
      sendPeerList: false,
      stopRoomStateOnJoin: true,
      enabled: true,
    },
    retry: {},
  },
  destinations: {
    rtmpDestinations: {
      default: {
        name: "default",
        width: 1280,
        height: 720,
        maxDuration: 240 * 60,
        recordingEnabled: false,
        role: "",
      },
    },
    hlsDestinations: {
      default: {
        name: "default",
        role: "",
        maxDuration: 14400, // 30*60, 4*60*60, 8*60*60
        layers: [...defaultLayers],
        playlistType: "live", // event or live
        numPlaylistSegments: 300, // 3, 5,12
        videoFrameRate: 25, // 10, 20,30
        displayAspectRatio: "", // Not supported
        enableMetadataInsertion: false,
        enableStaticUrl: false,
        preset: "", //tune_efficiency, tune_quality, default, fullHD
        recording: {
          hlsVod: true,
          singleFilePerLayer: false,
          layers: [...defaultLayers],
          thumbnails: {
            enabled: false,
            width: defaultLayers[0].width, //240, width, width
            height: defaultLayers[0].height, //240, height, height
            offsets: [
              60, // Only one offset supported
            ],
          },
          presignDuration: 259200, //60*60, 3*24*60*60, 7*24*60*60
        },
      },
    },
    transcriptions: {
      default: {
        name: "default",
        role: "",
        modes: [],
        outputModes: [],
        customVocabulary: [],
        summary: {
          enabled: false,
        },
      },
    },
  },
  recordings: [
    {
      name: "default",
      role: "",
      compositeRecording: {
        browserComposite: {
          enabled: false,
          autoStart: false,
          audioOnly: false,
          autoStopTimeout: 0,
          width: 1280,
          height: 720,
        },
        customComposite: {
          enabled: false,
        },
      },
      trackRecording: {
        enabled: false,
      },
      streamRecording: {
        enabled: false,
        width: 1280,
        height: 720,
      },
      maxDuration: 14400,
      thumbnails: {
        width: 0,
        height: 0,
      },
      presignDuration: 259200,
    },
  ],
};

export default policyTemplate;
